<template>
  <div>
    <b-row class="border-bottom py-3" v-if="showReadinessDate">
      <b-col cols="12" lg="3" class="text-muted">
        <template v-if="order.is_precheck">
          {{ $t('inputs.headings.precheck-readiness_date') }}:
        </template>
        <template v-else-if="order.is_activation">
          {{ $t('inputs.headings.activation-readiness_date') }}:
        </template>
        <template v-else>{{ $t('inputs.headings.readiness_date') }}:</template>
      </b-col>
      <b-col cols="12" lg="9">
        {{ order.job.readiness_date | moment('L') }}
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3" v-if="showDeliveryDate">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('inputs.headings.delivery_date') }}:
      </b-col>
      <b-col cols="12" lg="9">
        <template
          v-if="
            order.opportunity.email_type == 'delivery_to_partner_with_assembly'
          "
        >
          {{ $t('no-information') }}
        </template>
        <template v-else>{{ order.job.date_expected | moment('L') }}</template>
      </b-col>
    </b-row>
    <template v-if="!short">
      <b-row class="border-bottom py-3" v-if="availabilityDates.length > 0">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('inputs.headings.availability_dates') }}:
        </b-col>
        <b-col cols="12" lg="9">
          <ul class="mb-0">
            <li v-for="(date, index) in availabilityDates" :key="index">
              <strong>{{ date.start | moment(' dd, L') }},</strong>
              <span>{{ date.start | moment('H:mm') }}</span>
              -
              <strong v-if="isMultidayPeriod(date.start, date.end)">
                {{ date.end | moment(' dd, L') }},
              </strong>
              <span>{{ date.end | moment('H:mm') }}</span>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3" v-if="displayDateTimeSection">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('date-and-time') }}:
        </b-col>
        <b-col cols="12" lg="9">
          <template v-if="!confirmedAppointment">
            <i18n
              path="order-details.appointment-prompt.text"
              tag="p"
              class="mb-0"
            >
              <a
                slot="more_link"
                href="#order-actions"
                @click.prevent="focusOnActions"
              >
                {{ $t('order-details.appointment-prompt.link-label') }}
              </a>
            </i18n>
            <template v-if="type === 'open'">
              <button
                class="btn-unreachable"
                v-b-modal:[`customer-unreachable-${order.sfid}`]
              >
                {{ $t('customer-unreachable') }}?
              </button>
            </template>
          </template>
          <template v-else-if="order.job.appointment_start_timestamp">
            <strong>
              {{ order.job.appointment_start_timestamp | moment('L') }}
            </strong>
            ,
            {{ order.job.appointment_start_timestamp | moment('H:mm') }} -
            <strong
              v-if="
                isMultidayPeriod(
                  order.job.appointment_start_timestamp,
                  order.job.appointment_end_timestamp,
                )
              "
            >
              {{ order.job.appointment_end_timestamp | moment('L') }},
            </strong>
            {{ order.job.appointment_end_timestamp | moment('H:mm') }}
          </template>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="canDelegate(order)" class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('delegate.order-details-title') }}:
      </b-col>
      <b-col cols="12" lg="9">
        <b-button variant="outline-primary" @click="$emit('delegate')">
          <BIconDiagram3Fill class="mx-2" />
          {{ $t('delegate.order-details') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('partner-order-eid') }}:
      </b-col>
      <b-col cols="3">
        <OrderEid :order="order" :allowPartnerEidEdit="false" />
      </b-col>
    </b-row>
    <b-row v-if="order.delegated_to.email" class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('orders-list.delegated-to') }}:
      </b-col>
      <b-col cols="12" lg="9">
        {{ order.delegated_to.first_name }} {{ order.delegated_to.last_name }}
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('project-id') }}:</b-col>
      <b-col cols="12" lg="9">
        {{ order.project.name }}
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('address') }}:</b-col>
      <b-col cols="12" lg="9">
        <a
          :href="`https://maps.google.com/?q=${order.customer.shipping_address.street}+${order.customer.shipping_address.city}+${order.customer.shipping_address.postal_code}`"
          target="_blank"
        >
          {{ order.customer.name }},
          <br />
          {{ order.customer.shipping_address.street }},
          <br />
          <template v-if="order.customer.company_name">
            {{ order.customer.company_name }},
            <br />
          </template>
          {{ order.customer.shipping_address.postal_code }}
          {{ order.customer.shipping_address.city }}
        </a>
        <div v-if="order.job_contact_person.first_name" class="mb-0">
          <p class="mb-0">
            <strong>{{ $t('contact-person.title') }}:</strong>
          </p>
          <p class="my-0">
            {{
              `${order.job_contact_person.first_name} ${order.job_contact_person.last_name}`
            }}
          </p>
          <br />
        </div>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">
        {{
          order.job_contact_person ? $t('contact-person.phone') : $t('phone')
        }}:
      </b-col>
      <b-col cols="12" lg="9">
        <template v-if="phones.length > 0">
          <span v-for="(phone, index) in phones" :key="index">
            <a :href="`tel:${phone}`" @click="contactCustomer">{{ phone }}</a>
            <span v-if="index != phones.length - 1">,</span>
          </span>
        </template>

        <span v-else>{{ $t('none') }}</span>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">
        {{
          order.job_contact_person ? $t('contact-person.email') : $t('e-mail')
        }}:
      </b-col>
      <b-col cols="12" lg="9">
        <a
          v-if="order.customer.email"
          :href="`mailto:${customerEmail}?cc=partner@getitdone.rocks&subject=${$t(
            'contact-person.mail-subject',
          )}%20${order?.job?.name}`"
        >
          {{ customerEmail }}
        </a>
        <span v-else>{{ $t('none') }}</span>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('remark') }}:</b-col>
      <b-col cols="12" lg="9">
        <comments
          :job-api-id="order.api_id"
          :disable-new-comments="shouldDisableComments"
        />
      </b-col>
    </b-row>
    <b-row
      class="border-bottom py-3"
      v-if="Array.isArray(formAnswers) && formAnswers.length > 0"
    >
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('inputs.headings.form_answers') }}:
      </b-col>
      <b-col cols="12" lg="9">
        <form-answers :answers="formAnswers" />
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3" v-if="order.job.professional_comment">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('professional_comment_customer') }}:
      </b-col>
      <b-col cols="12" lg="9">
        <textarea
          :value="order.job.professional_comment"
          readonly
          wrap="soft"
          rows="8"
          max-rows="12"
          class="form-control"
        ></textarea>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('tasks') }}:</b-col>
      <b-col cols="12" lg="9">
        <services-preview
          :services="getServiceAndAdditionalItems"
          :current-price-purchase="pricePurchasingForPartner"
          :use-price-purchase="true"
          :show-price-purchase="!hidePricePurchasing"
          :show-value-entry="true"
          :opportunity-id="order.opportunity.sfid"
          :custom_services="order.custom_services"
        />
      </b-col>
    </b-row>

    <b-row class="border-bottom py-3" v-if="order.job.eid">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('eid') }}:</b-col>
      <b-col cols="12" lg="9">{{ order.job.eid }}</b-col>
    </b-row>

    <b-row class="border-bottom py-3" v-if="order.job.invoicing_eid">
      <b-col cols="12" lg="3" class="text-muted">
        {{ $t('invoicing_eid') }}:
      </b-col>
      <b-col cols="12" lg="9">{{ order.job.invoicing_eid }}</b-col>
    </b-row>

    <b-row class="border-bottom py-3" v-if="order.products.length">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('products') }}:</b-col>
      <b-col cols="12" lg="9">
        <div
          :class="order.products.length > 1 ? 'border-bottom pb-3' : ''"
          v-for="(product, index) in order.products"
          :key="index"
        >
          <span>
            <strong>{{ product.quantity }} x {{ product.name }}</strong>
            <b-badge variant="secondary" class="ml-2">
              {{
                product.supplier === 'partner'
                  ? $t('products-order.needs-to-be-ordered')
                  : `${$t('products-order.will-be-ordered')}` +
                    ' ' +
                    $t(`products-order.${product.supplier}`)
              }}
            </b-badge>
          </span>
          <div v-if="product.serial_number" class="text-muted small">
            SN: {{ product.serial_number }}
          </div>
          <div v-if="product.chargepoint_number" class="text-muted">
            Chargepoint: {{ product.chargepoint_number }}
          </div>
          <div v-if="checkProductDetails">
            <b-icon-box class="mr-2" />
            {{ $t('products-order.tracking-link') }}:
            <a :href="getProductOrderInfo(product.id, 'url')" target="_blank">
              {{ getProductOrderInfo(product.id, 'trackingID') }}
            </a>
          </div>
          <div v-if="checkProductDetails">
            {{ $t('products-order.shipping-to') }}:
            <b-badge variant="secondary">
              {{
                $t(
                  `products-order.${getProductOrderInfo(product.id, 'shipTo')}`,
                )
              }}
            </b-badge>
          </div>
        </div>
      </b-col>
    </b-row>
    <products-list-info
      :context="'orders'"
      :orderSfid="order.sfid"
      :products="order.products"
    />

    <template v-for="document of allDocuments">
      <b-row
        v-if="document.files.length"
        :key="document.resource"
        class="border-bottom py-3"
      >
        <b-col cols="12" lg="3" class="text-muted mb-2">
          {{ $t(`order-details.${document.resource}`) }}:
        </b-col>
        <b-col cols="12" lg="9">
          <span
            v-for="(file, index) in document.files"
            :key="index"
            style="width: fit-content"
            v-b-tooltip.hover
            :title="`${$t('order-details.tooltip')}`"
          >
            <a
              :class="`btn btn-sm px-3 mr-2 mb-2 rounded-pill ${shouldBeDisabled(
                file,
              )} ${
                file.resource === JobMediaFileTypeEnum.CUSTOMER_BLUEPRINT
                  ? 'btn-info'
                  : 'btn-primary'
              }`"
              :href="buildProtocolLink(file)"
              target="_blank"
              @click="file.tracking ? gaTrackPdfDownload : null"
            >
              <i class="material-icons align-middle size-lg">
                <template v-if="file.type == 'pdf'">picture_as_pdf</template>
                <template v-else>image</template>
              </i>
              {{ file.translateName ? $t(file.name) : file.name }}
              {{ index > 0 ? `[${index}]` : null }}
            </a>
          </span>
        </b-col>
      </b-row>
    </template>

    <b-row class="border-bottom py-3" v-if="onHoldReasons.length > 0">
      <b-col cols="12" lg="3" class="text-muted mb-2">
        {{ $t('on-hold-reasons') }}:
      </b-col>
      <b-col cols="12" lg="9">
        <div
          v-for="(reason, index) in filteredOnHoldReasons"
          :key="`reason-${index}`"
        >
          <b-badge variant="warning" class="gid-onhold-badge">
            {{ $t(`state.${reason.state}`) }}
          </b-badge>
          <template v-if="reason.event">
            -&gt;
            <b-button
              size="sm"
              variant="primary"
              @click="$emit('sendStateEvent', reason.event)"
            >
              {{ $t(`state-events.${reason.event}`) }}
            </b-button>
          </template>
          <template v-else-if="reason.custom">
            -&gt;
            <b-button
              size="sm"
              variant="primary"
              @click="reason.custom.action"
              :disabled="loadingAction"
            >
              <b-spinner v-if="loadingAction" small></b-spinner>
              {{ reason.custom.label }}
            </b-button>
          </template>
        </div>
        <div
          v-if="productDeliveryReason"
          class="d-flex flex-row align-items-center my-3"
          style="gap: 30px"
        >
          <b-badge variant="warning" class="gid-onhold-badge">
            {{ $t(`state.${productDeliveryReason.state}`) }}
          </b-badge>
          <div class="d-flex flex-column" style="gap: 30px">
            <div
              class="d-flex flex-column align-items-start"
              v-for="(order, index) in productDeliveryReason.orders"
              :key="index"
            >
              <strong
                v-for="product in order.items"
                :key="product.id"
                class="gid-product-name"
                :title="product.name"
              >
                {{ product.quantity }} x {{ product.name }}
              </strong>
              <b-button
                size="sm"
                variant="primary"
                @click="$emit('sendProductDelivery', order)"
              >
                {{ $t(`confirm-receipt`) }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="border-bottom py-3" v-if="shouldDisplayActivationNotice">
      <b-col cols="12" lg="9">
        <p><strong>Wichtiger Hinweis</strong></p>
        <p>
          Bis auf Weiteres muss vor der Inbetriebnahme der BayWa Wallboxen eine
          Einstellung von uns (GID) vorgenommen werden, die erst passieren kann,
          sobald die Wallbox am Strom hängt. Da vor dieser Einstellung die
          finale Inbetriebnahme von Ihnen nicht abgeschlossen werden kann,
          bitten wir unbedingt um eine Kontaktaufnahme, wenn die Wallbox am
          Strom hängt, unter
          <strong>
            <a slot="phone" href="tel:+4930577113981">030-577113981</a>
          </strong>
          .
        </p>
      </b-col>
    </b-row>
    <CustomerUnreachableModal :order="order" :isOrderDetails="true" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ServicesPreview from '@gid/vue-common/components/ServicesPreview.vue';
import ProductsListInfo from '@/components/ProductsListInfo.vue';
import FormAnswers from '@gid/vue-common/components/inputs/FormAnswers.vue';
import Comments from '@gid/vue-common/components/Comments';
import { JobStatusEnum, UserRoleEnum, JobMediaFileTypeEnum } from '@gid/models';
import { BIconBox, BIcon, BIconDiagram3Fill } from 'bootstrap-vue';
import CanDelegateOrder from './CanDelegateOrderMixin';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';
import { BAYWA_DIENSTWAGENFAHRER_OPP } from './Order.vue';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import CustomerUnreachableModal from './CustomerUnreachableModal.vue';
import OrderEid from '@/components/orders/OrderEidAddEdit';

const stateToEventMap = {
  [`${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.PARTNER_QUOTE_VALIDATION}.pending-validation`]: `PARTNER_QUOTE_VALIDATED`,
  [`${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_PREWORK_BEFORE_ACCEPTED}.waiting`]: `CUSTOMER_PREWORK_BEFORE_ACCEPTED_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_NOT_REACHABLE}.unreachable`]: `CUSTOMER_REACHED`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PREWORK}.awaiting`]: `CUSTOMER_PREWORK_DONE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_INFO}.awaiting`]: `PARTNER_INFO_PROVIDED`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_PAUSE_GENERIC}.paused`]: `PARTNER_UNPAUSE`,
  [`${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`]: `BRAND_PRODUCT_ORDERING_DONE`,
};
export default {
  components: {
    Comments,
    ServicesPreview,
    ProductsListInfo,
    FormAnswers,
    BIconBox,
    BIconDiagram3Fill,
    CustomerUnreachableModal,
    OrderEid,
  },
  mixins: [CanDelegateOrder],
  props: {
    order: Object,
    actionOptions: Object,
    short: Boolean,
    hidePricePurchasing: Boolean,
    productsOrderDetails: Array,
  },
  data() {
    return {
      JobMediaFileTypeEnum,
      formAnswers: [],
      protocols: {
        orderDetails: [],
        protocol: [],
      },
      loadingAction: false,
      partnerBriefingDocuments: [],
      type: this.$route.params.type,
    };
  },
  computed: {
    ...mapGetters(['user', 'contactProfiles']),
    shouldBeDisabled() {
      return (file) => {
        return this.order.async_appointment && 'disabled';
      };
    },
    isEnercityWallbox() {
      return ['0060800000wd8lbAAA'].includes(this.order.opportunity.sfid);
    },
    isBaywaDienstwagenfahrerOpp() {
      return this.order?.opportunity.sfid === BAYWA_DIENSTWAGENFAHRER_OPP;
    },
    shouldDisplayActivationNotice() {
      return (
        [
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.STARTED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING,
        ].includes(this.order?.job?.status) &&
        this.order?.is_installation &&
        this.isBaywaDienstwagenfahrerOpp
      );
    },
    shouldDisableComments() {
      return [
        JobStatusEnum.CANCELLED,
        JobStatusEnum.INVOICE_SENT,
        JobStatusEnum.CLOSED,
      ].includes(this.order?.job?.status);
    },
    customerEmail() {
      return this.order.job_contact_person.email
        ? `${this.order.job_contact_person.email}`
        : this.order.customer.email;
    },
    phones() {
      if (this.order.job_contact_person.phone) {
        return [this.order.job_contact_person.phone];
      }
      let phones = [];
      ['phone', 'mobile_phone'].forEach((phone) => {
        if (this.order.customer[phone]) phones.push(this.order.customer[phone]);
      });
      return phones;
    },
    needsDates() {
      return (
        this.actionOptions &&
        this.actionOptions.statuses &&
        this.actionOptions.statuses.some(
          (status) => status.name == JobStatusEnum.APPOINTMENT_CONFIRMED,
        )
      );
    },
    confirmedAppointment() {
      return (
        this.order.job.appointment_start_timestamp &&
        [
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.STARTED,
          JobStatusEnum.FINISHED,
          JobStatusEnum.APPROVAL_PENDING,
        ].includes(this.order.job.status)
      );
    },
    showDeliveryDate() {
      if (this.order.job.date_expected) {
        const deliveryDate = this.$moment(this.order.job.date_expected).startOf(
          'day',
        );
        const createdDate = this.$moment(this.order.job.created_date).startOf(
          'day',
        );
        return deliveryDate.isAfter(createdDate);
      }
      return false;
    },
    showReadinessDate() {
      return !!this.order.job.readiness_date;
    },
    availabilityDates() {
      if (this.needsDates) {
        return this.order.job.dates.filter((date) => {
          const start = this.$moment(date.start).startOf('day');
          const nowDate = this.$moment().startOf('day');
          const deliveryDate = this.order.job.date_expected
            ? this.$moment(this.order.job.date_expected).startOf('day')
            : this.$moment(this.order.job.date_created).startOf('day');
          return start.isAfter(nowDate) && start.isAfter(deliveryDate);
        });
      }
      return [];
    },
    displayDateTimeSection() {
      return (
        !this.order.async_appointment &&
        (this.needsDates || this.order.job.appointment_start_timestamp)
      );
    },
    checkProductDetails() {
      return this.productsOrderDetails?.length;
    },
    getServiceAndAdditionalItems() {
      return [
        ...this.order.services,
        ...this.order.additional_items.map((item) => {
          return { ...item, ...{ hints: undefined } };
        }),
      ];
    },
    pricePurchasingForPartner() {
      return this.order.job.service_price_purchasing_sum;
    },
    onHoldReasons() {
      return (
        this.order.job.states?.list?.[0]?.startsWith(this.order.job.status) &&
        this.order.job.states?.list?.[0].includes('.')
          ? this.order.job.states?.list
          : []
      ).map((state) => {
        const event = stateToEventMap[state];
        return this.actionOptions.jobServiceEvents.includes(event)
          ? { state, event }
          : { state };
      });
    },
    filteredOnHoldReasons() {
      const hiddenStates = [
        `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-delivery`,
      ];
      return this.onHoldReasons
        .filter(({ state }) => !hiddenStates.includes(state))
        .map((reason) => {
          if (
            reason.state ===
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`
          ) {
            if (
              !this.user?.account.restrict_protocol_submission ||
              this.contactProfiles.some((r) =>
                ['admin', 'company owner'].includes(r),
              )
            ) {
              reason.custom = {
                action: this.sendAbsentCustomerReminder,
                label: this.$t('state-actions.prompt-customer-signature'),
              };
            }
          }
          return reason;
        });
    },
    productDeliveryReason() {
      const pdReason = this.onHoldReasons.find(
        ({ state }) =>
          state ===
          `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-delivery`,
      );
      if (pdReason) {
        const productsNoTrackingId = this.productsOrderDetails
          .filter(({ trackingID }) => !trackingID)
          .map((productOrder) => ({
            ...productOrder,
            items: productOrder.items.map(({ id }) =>
              this.order.products.find((product) => product.id === id),
            ),
          }));
        return { ...pdReason, orders: productsNoTrackingId };
      }
      return null;
    },
    allDocuments() {
      return [
        {
          resource: 'available-info',
          files: this.protocols.orderDetails,
        },
        {
          resource: 'partner-briefing',
          files: this.partnerBriefingDocuments.filter(
            (pbd) => pbd.resource === 'partner-briefing',
          ),
        },
        {
          resource: 'additional-documents',
          files: this.partnerBriefingDocuments.filter(
            (pbd) => pbd.resource === 'additional-documents',
          ),
        },
        {
          resource: 'protocol',
          files: this.protocols.protocol,
        },
      ];
    },
  },
  methods: {
    async sendAbsentCustomerReminder() {
      this.loadingAction = true;
      try {
        await axios.post('/api/partner/send-email', {
          template_name: 'customer/missing_presence',
          job_id: this.order.job.sfid,
        });
        this.$bvToast.toast(this.$t('e-mail-sent'), {
          title: this.$t('successful'),
          variant: 'success',
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast(this.$t('_errors.general.title'), {
          title: this.$t('_errors.general.title'),
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.loadingAction = false;
      }
    },
    getProductOrderInfo(productId, prop) {
      return this.productsOrderDetails?.find(({ items }) =>
        items?.some(({ id }) => id == productId),
      )?.[prop];
    },
    contactCustomer() {
      this.createComment(this.order.job.api_id, this.$t('call.customer'), [
        UserRoleEnum.ADMIN,
        UserRoleEnum.PARTNER,
        UserRoleEnum.BRAND,
      ]);
    },
    createComment(jobApiId, message, visibleFor) {
      const commentData = {
        actionType: CommentActionTypeEnum.PHONE,
        jobApiId,
        message,
        visibleForRoles: visibleFor,
      };
      axios.post(`/data-api/comment`, commentData);
    },
    focusOnActions() {
      this.$emit('scrollIntoView', { block: 'start', behavior: 'smooth' });
    },
    gaTrackPdfDownload() {
      this.$gtag.event('Single pdf download', {
        event_category: 'Opportunity',
        event_label: this.order.opportunity.name,
      });
    },
    isMultidayPeriod(start, end) {
      return !this.$moment(start).isSame(end, 'day');
    },
    buildProtocolLink(file) {
      return file.linkParams
        ? `${file.link}?${file.linkParams}`
        : `${file.link}`;
    },
    fetchFormAnswers() {
      axios
        .get(`/order-intake-forms-api/form/answers/${this.order.api_id}`)
        .then((response) => {
          this.formAnswers = response.data;
        });
    },
    fetchProtocols() {
      axios
        .get(`/data-api/partner/protocols/of-job/${this.order.job.sfid}`)
        .then((response) => {
          this.protocols = response.data.reduce(
            (acc, curr) => {
              if (
                ['protocol-customer', 'protocol', 'cancellation'].includes(
                  curr.resource,
                )
              ) {
                acc.protocol.push(curr);
              }
              if (
                [
                  JobMediaFileTypeEnum.PROPAGATED_PROTOCOL,
                  JobMediaFileTypeEnum.PLANNING_CUSTOMER,
                  JobMediaFileTypeEnum.CUSTOMER_BLUEPRINT,
                ].includes(curr.resource)
              ) {
                acc.orderDetails.push(curr);
              }
              return acc;
            },
            {
              orderDetails: [],
              protocol: [],
            },
          );
        })
        .catch((error) => {
          console.error('Protocols could not be fetched');
        });
    },
    fetchPartnerBriefingDocuments() {
      axios
        .get(
          `/data-api/partner/partner-briefing-documents/${this.order.opportunity.sfid}/${this.order.api_id}`,
        )
        .then(({ data }) => (this.partnerBriefingDocuments = data));
    },
  },
  created() {
    this.fetchProtocols();
    this.fetchFormAnswers();
    this.fetchPartnerBriefingDocuments();
  },
};
</script>
<style lang="scss" scoped>
.gid-product-name {
  max-width: 400px;
  font-size: 14px;
}

.gid-onhold-badge {
  font-size: 15px;
  font-weight: 700;
}

.btn-unreachable {
  background: none;
  border: none;
  color: var(--primary);
  padding: 0;
  transition: all 0.12s ease;

  &:hover {
    text-decoration: underline;
    color: #025c4c;
  }
}
</style>
