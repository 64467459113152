<template>
  <div v-if="order && order.job.sfid">
    <h2 class="mt-3">
      {{ order.opportunity && order.opportunity.name }}
    </h2>
    <h5 class="mt-2 mb-4">{{ order.job.name }}</h5>
    <b-alert :show="successMsg.type" dismissable :variant="successMsg.type">
      <h3 class="alert-heading">{{ successMsg.title }}</h3>
      <p v-if="successMsg.description">{{ successMsg.description }}</p>
    </b-alert>

    <order-summary
      v-if="availableActionsFetched"
      :order="order"
      :action-options="options"
      :hide-price-purchasing="hidePricePurchasing"
      :products-order-details="orderDetails"
      @delegate="$refs.delegate.show(order.job.sfid)"
      @sendStateEvent="prepareEventChange"
      @sendProductDelivery="prepareProductDelivery"
      @scrollIntoView="orderIntakeFormsScrollIntoView"
    />
    <b-row v-else id="summary" class="bg-light border-bottom py-3">
      <b-col cols="12" lg="9" offset-lg="3">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
    </b-row>
    <template v-if="!change">
      <b-row
        ref="order-actions"
        class="border-bottom py-3 py-lg-5 bg-light align-items-center"
      >
        <b-col cols="12" lg="3" class="text-muted">
          <h3 class="font-weight-light mb-3 mb-lg-0">
            {{ $t('order-details.actions') }}:
          </h3>
        </b-col>
        <b-col
          cols="12"
          lg="9"
          class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
        >
          <b-button
            v-for="(action, index) in statusActions"
            :key="index"
            @click="prepareChange({ status: action.status })"
            :variant="actionButtonVariantStyle(action)"
            size="lg"
            class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
          >
            {{ action.label }}
          </b-button>
          <span
            class="mr-sm-2 mb-2 mb-sm-0 text-center"
            v-if="statusActions.length > 0"
          >
            {{ $t('or') }}
          </span>
          <b-button
            size="lg"
            @click="crCollapse = !crCollapse"
            class="gid-cr-select"
          >
            {{ crCollapse ? $t('cancel') : $t('cancel-reason') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <cr-collapse
          :extended="crCollapse"
          :cancellations="options.cancellations"
          @crClick="(value) => prepareChange({ cancellation: value })"
        ></cr-collapse>
      </b-row>
    </template>
    <template v-else-if="change.loading && change.loading.form">
      <b-row ref="order-actions" class="bg-light border-bottom py-3">
        <b-col cols="12" lg="9" offset-lg="3">
          <b-spinner variant="secondary"></b-spinner>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row ref="order-actions" class="bg-light border-bottom py-3">
        <b-col
          cols="12"
          lg="9"
          offset-lg="3"
          class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
        >
          <h2 class="font-weight-light mr-sm-2">{{ change.title }}</h2>
          <template
            v-if="
              change.status &&
              !change.showCancel &&
              options.cancellations.length > 0
            "
          >
            <span class="mr-sm-2 mb-2 mb-sm-0 text-center">{{ $t('or') }}</span>
            <b-button @click="crCollapse = !crCollapse" class="gid-cr-select">
              {{ crCollapse ? $t('cancel') : $t('cancel-reason') }}
            </b-button>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <cr-collapse
          :extended="crCollapse"
          :cancellations="options.cancellations"
          @crClick="(value) => prepareChange({ cancellation: value })"
        ></cr-collapse>
      </b-row>
      <b-row
        class="bg-light border-bottom py-3"
        v-if="
          change.cancellation &&
          change.cancellation.instructions &&
          change.cancellation.instructions[locale]
        "
      >
        <b-col cols="12" lg="9" offset-lg="3">
          <h5 class="font-weight-light mr-sm-2">
            {{ change.cancellation.instructions[locale] }}
          </h5>
        </b-col>
      </b-row>

      <div :class="{ 'gid-sign-view': signView }">
        <ValidationObserver ref="observer">
          <div v-if="signView">
            <h2 class="mt-2">
              {{
                $t(
                  'order-details.sign-view.title.' +
                    (change.cancellation ? 'change' : 'protocol'),
                  { job_name: order.job.name },
                )
              }}
            </h2>
            <p v-if="change.cancellation">
              {{ $t('order-details.sign-view.intro') }}
            </p>
            <input-holder
              v-if="
                order.products.length &&
                invoiceTarget === InvoicingTargetEnum.CUSTOMER
              "
              input-name="products"
            >
              <products-preview :products="order.products" />
            </input-holder>
            <input-holder
              v-if="servicesConfirmList.length"
              :input-name="servicesInput ? 'services_preview' : 'services'"
            >
              <services-preview
                :services="servicesConfirmList"
                :custom_services="customServices"
                :opportunity-id="order.opportunity.sfid"
                :services-endpoint="servicesEndpoint"
                :current-price-list="order.job.price_lists"
                :use-price-list="true"
                :show-price-list="!!servicesInput"
                :show-value-entry="true"
                :hide-tools="true"
                :hide-materials="true"
                :add-vat="currencyVatConfig"
                :invoiceTarget="invoiceTarget"
                :additionalProductsListPrice="productsTotalListPrice"
              />
            </input-holder>
            <input-holder
              v-if="
                order.products.length &&
                invoiceTarget !== InvoicingTargetEnum.CUSTOMER
              "
              input-name="products"
            >
              <div v-for="(product, index) in order.products" :key="index">
                {{ product.quantity }} x {{ product.name }}
              </div>
            </input-holder>
            <b-row class="border-bottom py-3">
              <b-col cols="12" lg="3" class="text-muted">
                {{ $t('remark') }}:
              </b-col>
              <b-col cols="12" lg="9">
                <comments :job-api-id="order.api_id" />
              </b-col>
            </b-row>
          </div>
          <div>
            <template v-for="{ input, available, extra } in displayInputs">
              <input-holder
                v-if="available"
                :key="input.name"
                :input-name="input.name"
                :title="extra.title"
                :type="input.component"
              >
                <template v-if="input.component === 'order-intake-forms'">
                  <div
                    v-if="
                      summaryShown &&
                      isCustomerSignatureMissing &&
                      !order.is_remote
                    "
                    class="disabled-prompt"
                  >
                    <div class="box">
                      <b-icon
                        variant="danger"
                        class="h3 m-0 mr-2"
                        icon="exclamation-triangle-fill"
                      />
                      {{ $t('inputs.customer-signature.disabled-text') }}
                    </div>
                  </div>
                  <component
                    :is="input.component"
                    :type="input.type"
                    :input-name="input.name"
                    v-bind.sync="input.extra"
                    :class="{
                      'oi-disabled':
                        summaryShown &&
                        isCustomerSignatureMissing &&
                        !order.is_remote,
                    }"
                    @redirect="orderIntakeFormsRedirect"
                    @fetched="orderIntakeFormsFetched"
                    @summary="orderIntakeFormsSummary"
                    @scrollIntoView="orderIntakeFormsScrollIntoView"
                  />
                </template>
                <template v-else>
                  <component
                    :is="input.component"
                    :type="input.type"
                    :input-name="input.name"
                    v-bind.sync="extra"
                    context="partner"
                  />
                </template>
              </input-holder>
            </template>
          </div>
          <b-row class="bg-light border-bottom py-3" v-if="change.description">
            <b-col cols="12" lg="9" offset-lg="3">
              {{ change.description }}
            </b-col>
          </b-row>
          <b-row class="bg-light border-bottom py-3" v-if="hasAction">
            <b-col cols="12" lg="9" offset-lg="3">
              <b-button
                v-if="showSignViewButton"
                size="lg"
                variant="primary"
                @click="showSignView"
                class="mb-2 mb-sm-0 mr-sm-2 d-block d-sm-inline-block"
              >
                {{ $t('order-details.sign-view.button') }}
              </b-button>

              <b-button
                v-if="showConfirmButton && !crCollapse"
                @click="confirmChange"
                :disabled="change.loading && change.loading.button"
                variant="primary"
                size="lg"
                class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
                :class="{
                  'btn-confirm-action-warning':
                    isCustomerSignatureMissing &&
                    !!customerSignatureStatusVariant,
                }"
              >
                <b-spinner
                  v-if="change.loading && change.loading.button"
                  class="gid-spinner--button mr-2 ml-n2"
                ></b-spinner>
                {{ change.confirmLabel }}
              </b-button>

              <b-button
                v-if="crCollapse"
                @click="crCollapse = false"
                variant="secondary"
                size="lg"
                class="text-uppercase px-5 mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
              >
                {{ $t('cancel') }}
              </b-button>

              <b-button
                v-if="change.showCancel || signView"
                @click="cancelChange"
                size="lg"
                class="mr-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block"
              >
                {{ $t('cancel') }}
              </b-button>
              <div
                class="text-muted my-3"
                v-if="
                  useSignView &&
                  !uploadImages &&
                  !signView &&
                  showUploadCheckbox
                "
              >
                {{ $t('or') }}
              </div>
              <b-form-checkbox
                :class="['mt-3', { 'text-muted': uploadImages }]"
                v-model="uploadImages"
                v-if="showUploadCheckbox"
              >
                <h5>{{ $t('upload-signed-protocol') }}</h5>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <input-holder
            input-name="customer-signature"
            v-if="!!customerSignatureStatusVariant"
          >
            <customer-signature-status
              :order="order"
              :variant="customerSignatureStatusVariant"
              @signatureDeclined="onSignatureDecline"
            />
          </input-holder>
        </ValidationObserver>
      </div>
    </template>

    <b-row v-if="changeResult" class="border-bottom py-3">
      <b-col cols="12" lg="9" offset-lg="3">
        <b-alert show :variant="changeResult.type" class="mb-2">
          <h3 class="alert-heading">{{ changeResult.title }}</h3>
          <p v-if="changeResult.description">{{ changeResult.description }}</p>
          <template v-if="changeResult.type == 'error'">
            <p>
              <em>{{ $t('_errors.report-explain') }}</em>
            </p>
            <b-card
              v-if="
                changeResult.validationErrors &&
                changeResult.validationErrors.errors
              "
              class="text-body"
            >
              <div
                v-for="(input, inputName) in changeResult.validationErrors
                  .errors"
                :key="inputName"
              >
                <h4>{{ $t(`inputs.headings.${inputName}`) }}</h4>
                <ul class="mb-0">
                  <li v-for="(error, index) in input" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </b-card>
            <div v-if="changeResult.details">
              <b-collapse id="result-details" class="my-2">
                <b-card>
                  <small>
                    <pre>{{ changeResult.details }}</pre>
                  </small>
                </b-card>
              </b-collapse>
              <b-btn v-b-toggle.result-details class="mt-2">
                {{ $t('_errors.buttons.error-details') }}
              </b-btn>
            </div>
          </template>
        </b-alert>
      </b-col>
    </b-row>
    <delegate-order ref="delegate" @success="loadData" />
  </div>
  <h2 v-else-if="order && !order.job.sfid" class="mt-3 mb-5">
    {{ $t('order-processing') }}
  </h2>
  <not-found v-else-if="order === false" />
  <b-spinner v-else variant="secondary" class="my-3"></b-spinner>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// eslint can't detect the dynamic usage of the components

import axios from 'axios';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { computed } from 'vue';

import NotFound from '@/views/NotFound.vue';
import { COUNTS_REFRESH } from '@/store/sidebar.module';
import Summary from './Summary.vue';
import InputHolder from './InputHolder.vue';
import ServicesPreview from '@gid/vue-common/components/ServicesPreview.vue';
import ProductsPreview from '@gid/vue-common/components/ProductsPreview.vue';
import Comments from '@gid/vue-common/components/Comments';
import AccountDisplay from '@/components/AccountDisplay.vue';
import Appointment from '@gid/vue-common/components/inputs/Appointment.vue';
import DeliveryDate from '@gid/vue-common/components/inputs/DeliveryDate.vue';
import ReadinessDate from '@gid/vue-common/components/inputs/ReadinessDate.vue';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import Documentation from './inputs/Documentation.vue';
import OrderIntakeForms from '@gid/vue-common/components/OrderIntakeForms';
import CrCollapse from '@/components/CrCollapse';
import CustomerSignatureStatus, {
  CustomerSignatureStatusVariantEnum,
} from '@/components/CustomerSignatureStatus';
import Products from '@gid/vue-common/components/inputs/Products.vue';
import Services from '@gid/vue-common/components/inputs/Services.vue';
import DelegateOrder from './DelegateOrder';
import { BIcon, BIconExclamationTriangleFill } from 'bootstrap-vue';

import {
  CONTEXT_CREATE,
  CONTEXT_DESTROY,
  STORE_API_ERRORS,
} from '@gid/vue-common/store/validation.module/types';
import { filterNonInputErrors } from '@gid/vue-common/store/validation.module/utils';
import {
  JOB_INIT_INPUTS,
  JOB_UPDATE_EXTRA_INPUT,
  JOB_SET_EDIT,
} from '@gid/vue-common/store/jobs.module';

import {
  READINESS_DATE,
  DELIVERY_DATE,
  APPOINTMENT_DATE,
  PLANNING_CUSTOMER,
  PLANNING_PARTNER,
  DOCUMENTATION,
  DOCUMENTATION_PICTURE,
  CANCELLATION_DOCUMENTATION,
  PRODUCTS,
  SERVICES,
} from '@gid/vue-common/components/inputs/_types';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import {
  JobStatusEnum,
  CustomerSignatureStatusEnum,
  UserRoleEnum,
  InvoicingTargetEnum,
  toGenericInvoicingTarget,
  CustomerSignatureReasonEnum,
  PARTNER_TRACKING_ID,
  CARRIER_UNKNOWN_ID,
  FormOrderModificationCategoryEnum,
} from '@gid/models';

const VALIDATION_CONTEXT_PREFIX = 'order';
const inputsOrder = [
  PRODUCTS,
  SERVICES,
  READINESS_DATE,
  DELIVERY_DATE,
  APPOINTMENT_DATE,
  PLANNING_CUSTOMER,
  PLANNING_PARTNER,
  DOCUMENTATION_PICTURE,
  DOCUMENTATION,
  CANCELLATION_DOCUMENTATION,
];
export const BAYWA_DIENSTWAGENFAHRER_OPP = '0060800000vv2K6AAI';
const VODAFONE_OPPORTUNITY_SENDERNEUSORTIERUNG = '0060800000tKs8EAAS';

export default {
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconExclamationTriangleFill,
    AccountDisplay,
    Comments,
    NotFound,
    InputHolder,
    ServicesPreview,
    ProductsPreview,
    OrderIntakeForms,
    CrCollapse,
    CustomerSignatureStatus,
    DelegateOrder,
    'order-summary': Summary,
    'input-appointment_date': Appointment,
    'input-delivery_date': DeliveryDate,
    'input-readiness_date': ReadinessDate,
    'input-files_selector': FilesSelector,
    'input-documentation': Documentation,
    'input-documentation_picture': FilesSelector,
    'input-products': Products,
    'input-services': Services,
  },
  props: {
    job_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      order: null,
      availableActionsStatusesLoading: false,
      availableActionsCancellationsLoading: false,
      options: {
        statuses: [],
        cancellations: [],
        jobServiceEvents: [],
      },
      requestMixin: {},
      change: null,
      changeResult: null,
      signView: false,
      uploadImages: false,
      orderDetails: [],
      invoiceTarget: null,
      InvoicingTargetEnum,
      JobStatusEnum,
      crCollapse: false,
      scrollActionsIntoView: false,
      new_custom_services: [],
      customServicesErrors: {},
      summaryShown: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'contactProfiles', 'user', 'access_token']),
    ...mapState({
      successMsg: (state) => {
        return state.validation.successMsg;
      },
    }),
    customerSignatureStatusVariant() {
      if (
        this.order &&
        !this.order.is_remote &&
        [
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.STARTED,
          JobStatusEnum.FINISHED,
        ].includes(this.order.job.status)
      ) {
        return this.summaryShown
          ? CustomerSignatureStatusVariantEnum.REASON
          : CustomerSignatureStatusVariantEnum.PROMPT;
      }
      return null;
    },
    isCustomerSignatureMissing() {
      return [CustomerSignatureStatusEnum.MISSING].includes(
        this.order.job.customer_signature,
      );
    },
    newCustomServicesHaveErrors() {
      return this.new_custom_services.some((srvc) => !srvc.service_type);
    },
    productsTotalListPrice() {
      return this.order.products
        ?.map((product) => product.price_listing * product.quantity)
        .reduce((a, b) => a + b, 0);
    },
    actionsLabels() {
      let startedActionLabel = this.order.job.is_reclamation
        ? this.$t('order-details.action-labels.reclamation-start')
        : this.$t('order-details.action-labels.start');
      let appointmentConfirmedActionLabel = this.order.job.is_reclamation
        ? this.$t('order-details.action-labels.reclamation-appointment-set-up')
        : this.$t('order-details.action-labels.appointment-set-up');

      if (this.order.is_precheck) {
        startedActionLabel = this.$t(
          'order-details.action-labels.precheck-start',
        );
        appointmentConfirmedActionLabel = this.$t(
          'order-details.action-labels.precheck-appointment-set-up',
        );
      } else if (this.order.is_activation) {
        startedActionLabel = this.$t(
          'order-details.action-labels.activation-start',
        );
        appointmentConfirmedActionLabel = this.$t(
          'order-details.action-labels.activation-appointment-set-up',
        );
      } else if (this.order.is_meter_application) {
        startedActionLabel = this.$t(
          'order-details.action-labels.meter-application',
        );
      } else if (this.order.is_installation_preparation) {
        startedActionLabel = this.$t(
          'order-details.action-labels.installation-preparation',
        );
      } else if (this.order.is_defect_elimination) {
        startedActionLabel = this.$t(
          'order-details.action-labels.defect-elimination',
        );
      }

      return {
        [JobStatusEnum.APPOINTMENT_CONFIRMED]: appointmentConfirmedActionLabel,
        [JobStatusEnum.STARTED]: startedActionLabel,
        [JobStatusEnum.FINISHED]: this.$t('finish'),
        [JobStatusEnum.APPROVAL_PENDING]: this.$t(
          'order-details.action-labels.approval-pending',
        ),
      };
    },
    currencyVatConfig() {
      return this.order?.opportunity?.currency_tax_config;
    },
    isAddAppointmentWithMasterAccount() {
      return (
        (this.user?.is_partner_admin_and_master_account ||
          this.user?.is_company_owner_and_master_account) &&
        this.change.status === JobStatusEnum.APPOINTMENT_CONFIRMED
      );
    },
    disallowedActions() {
      return {
        [JobStatusEnum.ACCEPTED]: {
          [JobStatusEnum.STARTED]: {
            conditions: [!this.order.async_appointment],
          },
          [JobStatusEnum.APPOINTMENT_CONFIRMED]: {
            conditions: [
              this.order.async_appointment &&
                Object.values(this.options.statuses)
                  .map((x) => x.name)
                  .some((x) => [JobStatusEnum.STARTED].includes(x)),
            ],
          },
        },
        [JobStatusEnum.APPOINTMENT_CONFIRMED]: {
          [JobStatusEnum.APPOINTMENT_CONFIRMED]: {
            conditions: [true],
          },
        },
        [JobStatusEnum.STARTED]: {
          [JobStatusEnum.STARTED]: {
            conditions: [true],
          },
        },
        [JobStatusEnum.FINISHED]: {
          [JobStatusEnum.FINISHED]: {
            conditions: [true],
          },
        },
      };
    },
    statusActions() {
      let actions = [];
      this.options.statuses
        .filter((x) => this.isStatusAllowed(x))
        .forEach(({ name: status }) => {
          if (Object.keys(this.actionsLabels).includes(status)) {
            actions.push({
              status: status,
              label: this.actionsLabels[status],
            });
          }
        });
      return actions;
    },
    changeInputs() {
      return this.change && this.change.inputs ? this.change.inputs : [];
    },
    displayInputs() {
      let inputs = [];
      this.changeInputs.forEach((input) => {
        const displayInput = {
          input,
          available:
            input.enabled &&
            (!input.async ||
              (input.async &&
                input.fetchedChunks.every((x) => x) &&
                !this.isApplyingCancellationReason)),
          extra: { ...input.extra },
        };
        if (this.useSignView) {
          const isDocumentation =
            input.name === DOCUMENTATION ||
            input.name == CANCELLATION_DOCUMENTATION;
          if (this.signView) {
            if (isDocumentation) {
              displayInput.extra.variants = input.extra.variants.filter(
                (variant) => variant == 'protocol_signature',
              );
              displayInput.extra.title = this.change.cancellation
                ? 'signoff-change'
                : 'signoff-protocol';
              if (
                displayInput.extra.variants.length > 0 &&
                !this.uploadExclusive
              ) {
                inputs.push(displayInput);
              }
            } else if (
              [PLANNING_PARTNER, DOCUMENTATION_PICTURE].includes(input.name) &&
              input.data &&
              input.data.files &&
              input.data.files.length > 0
            ) {
              this.$set(displayInput.extra, 'readonly', true);
              inputs.push(displayInput);
            }
          } else {
            if (isDocumentation) {
              displayInput.extra.variants = input.extra.variants.filter(
                (variant) => variant != 'protocol_signature',
              );
              if (
                displayInput.extra.variants.length > 0 &&
                !this.signViewExclusive &&
                this.showUploadCheckbox == this.uploadImages
              ) {
                inputs.push(displayInput);
              }
            } else {
              inputs.push(displayInput);
            }
          }
        } else {
          inputs.push(displayInput);
        }
      });
      return inputs;
    },
    customServices() {
      const customServices = this.new_custom_services.map((service) => {
        if (service.name || service.hints_de || service.price_purchasing) {
          const pricePurchasing = Number(service.price_purchasing) || 0;
          const marginList =
            this.order.opportunity.custom_services_margin ?? 10;
          const marginSelling =
            this.order.opportunity.custom_services_margin_selling ?? 10;
          return {
            ...service,
            invoicing_target: toGenericInvoicingTarget(
              this.order.opportunity.invoicing_target,
            ),
            price_lists: pricePurchasing + (marginList / 100) * pricePurchasing,
            price_selling:
              pricePurchasing + (marginSelling / 100) * pricePurchasing,
            price_purchasing: pricePurchasing,
          };
        }
      });
      return [...this.order.custom_services, ...customServices];
    },
    sendInputs() {
      let inputs = this.$store.getters.jobInputsAllValues(this.job_id);
      if (PLANNING_PARTNER in inputs && !inputs[PLANNING_PARTNER]) {
        delete inputs[PLANNING_PARTNER];
      }
      if (this.isApplyingCancellationReason) {
        delete inputs['order-intake-protocol'];
        if (this.requestMixin.cancellation_reason_category === 'Jobanpassung') {
          inputs['custom_services'] = [];
          if (this.customServices.length) {
            inputs['custom_services'] = this.customServices;
          }
        }
      }

      if (inputs.services && inputs.services.length)
        inputs.services = inputs.services.filter(({ service }) => service);

      if (inputs.products && inputs.products.length)
        inputs.products = inputs.products.filter(({ product }) => product);

      return inputs;
    },
    servicesInput() {
      return this.changeInputs.find(({ name }) => name == SERVICES);
    },
    useSignView() {
      return this.change && this.change.useSignView ? true : false;
    },
    signViewExclusive() {
      return this.change && this.change.signViewExclusive ? true : false;
    },
    uploadExclusive() {
      return this.change && this.change.uploadExclusive ? true : false;
    },
    showSignViewButton() {
      return (
        this.useSignView &&
        !this.signView &&
        !this.uploadExclusive &&
        !this.uploadImages
      );
    },
    showConfirmButton() {
      return (
        !this.useSignView ||
        this.signView ||
        this.uploadImages ||
        this.uploadExclusive
      );
    },
    showUploadCheckbox() {
      return (
        this.useSignView &&
        !this.signViewExclusive &&
        !this.uploadExclusive &&
        !this.signView
      );
    },
    hasOnlyOrderIntakeProtocolInput() {
      if (this.change) {
        if (Object.keys(this.change).length === 0) {
          return false;
        }
        if (Object.keys(this.change).includes('inputs')) {
          if (
            this.change.inputs &&
            Array.isArray(this.change.inputs) &&
            this.change.inputs.length === 0
          ) {
            return false;
          }
        }
      }
      return (
        this.displayInputs.filter((x) => x.available).length === 1 &&
        this.changeInputs.some((x) => x.name === 'order-intake-protocol')
      );
    },
    hasAction() {
      let action = !this.hasOnlyOrderIntakeProtocolInput;
      if (this.isApplyingCancellationReason) {
        action = true;
      }
      return action;
    },
    isApplyingCancellationReason() {
      return !!this.requestMixin.cancellation_reason_id;
    },
    servicesEndpoint() {
      return `/api/partner/services/for-job/${this.job_id}`;
    },
    servicesConfirmList() {
      return this.servicesInput
        ? this.$store.getters.jobInputValue(this.job_id, SERVICES)
        : this.order.services;
    },
    productsConfirmList() {
      return this.productsInput
        ? this.$store.getters.jobInputValue(this.job_id, SERVICES)
        : this.order.products;
    },
    allowDuplicateServices() {
      // For now allow this only for th gardena hedges opportunity
      return this.order && this.order.opportunity.sfid === '0061p00000mv76wAAA';
    },
    validationContext() {
      return `${VALIDATION_CONTEXT_PREFIX}-${this.job_id}`;
    },
    hidePricePurchasing() {
      return (
        this.contactProfiles.length == 1 &&
        this.contactProfiles.includes('installer')
      );
    },
    availableActionsFetched() {
      return (
        !this.availableActionsStatusesLoading &&
        !this.availableActionsCancellationsLoading
      );
    },
  },
  watch: {
    job_id(newValue, oldValue) {
      this.loadData();
      this.destroyValidationContext(oldValue);
      this.createValidationContext(newValue);
    },
    sendInputs: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          // clear api validation errors
          this.changeResult = null;
        }
      },
    },
    signView(enabled) {
      if (enabled) {
        this.addBodyClass();
      } else {
        this.removeBodyClass();
      }
    },
    availableActionsFetched(value) {
      if (value && this.scrollActionsIntoView) {
        setTimeout(() => {
          this.$refs['order-actions']?.scrollIntoView();
        }, 500);
      }
    },
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.removeBodyClass();
    this.$store.dispatch('validation/STORE_SUCCESS_MESSAGE', {});
  },
  destroyed() {
    this.destroyValidationContext(this.job_id);
  },
  methods: {
    onSignatureDecline(order) {
      this.order = order;
    },
    addCustomerReachedComment() {
      axios.post(`/data-api/comment`, {
        actionType: CommentActionTypeEnum.PHONE,
        jobApiId: this.order.job.api_id,
        message: 'Kunde hat sich gemeldet, Termin wurde vereinbart.',
        visibleForRoles: [
          UserRoleEnum.ADMIN,
          UserRoleEnum.BRAND,
          UserRoleEnum.PARTNER,
        ],
      });
    },
    addBodyClass() {
      document.body.classList.add('gid-sign-view--open');
    },
    removeBodyClass() {
      document.body.classList.remove('gid-sign-view--open');
    },
    loadData(skipLoading) {
      if (!skipLoading) {
        this.order = null;
      }
      this.scrollActionsIntoView = this.$route.query.showActions;
      axios
        .get(`/api/partner/orders/${this.job_id}`)
        .then((response) => {
          this.order = response.data;
          this.invoiceTarget = this.order.opportunity.invoicing_target;
          this.$store.commit(
            'title',
            this.$t('order-with-number', { num: this.order.job.name }),
          );
          this.fetchProductOrders();
          this.fetchAvailableActions();
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.order = false;
          }
        });
    },
    async fetchProductOrders() {
      const orderedQuery = RequestQueryBuilder.create({
        search: {
          'job.id': { [CondOperator.IN]: [this.job_id] },
        },
      }).query();
      const productOrder = await axios
        .get(`/documents-api/product-orders?${orderedQuery}`)
        .then(({ data }) => data);

      this.orderDetails = [
        ...new Set(
          productOrder
            .sort(
              (firstEl, secondEl) => secondEl.created_at - firstEl.created_at,
            )
            .map((order) => ({
              id: order.id,
              items: order.items.map((item) => ({
                id: parseInt(item.product.id),
                name: item.productName,
              })),
              url: order?.carrier?.urlPattern?.replace(
                '{ID}',
                order.trackingID,
              ),
              trackingID: order.trackingID,
              carrierId: order.carrierId,
              shipTo: order.shipTo,
            })),
        ),
      ];
    },
    fetchAvailableActions() {
      this.availableActionsStatusesLoading = true;
      axios
        .post(`/api/partner/orders/set-status`, {
          preview: true,
          job_id: this.job_id,
        })
        .then((response) => {
          this.options.statuses = response.data.statuses
            .filter((status) => {
              return status.allowed;
            })
            .map((status) => ({
              name: status.job_status,
              label: status.partner_name[this.locale],
            }));
          this.tryAutoStatusAction();
        })
        .finally(() => {
          this.availableActionsStatusesLoading = false;
        });

      this.availableActionsCancellationsLoading = true;
      axios
        .get(`/api/partner/orders/${this.job_id}/cancellation-reasons`)
        .then((response) => {
          this.options.cancellations = response.data;
        })
        .catch((error) => {
          this.options.cancellations = error;
        })
        .finally(() => {
          this.availableActionsCancellationsLoading = false;
        });

      axios
        .get(
          `/jobs-api/status-rules/job/${this.order.job.api_id}/events-available`,
        )
        .then(({ data }) => {
          this.options.jobServiceEvents = data
            // handled specially in the orders list
            .filter((event) => event != 'CUSTOMER_UNREACHABLE');
        });
    },
    tryAutoStatusAction() {
      if (this.statusActions.length == 1) {
        this.prepareChange({ status: this.statusActions[0].status });
      }
    },
    prepareChange({ status, cancellation }) {
      let changeMixin;
      if (cancellation) {
        let cr = this.options.cancellations.find((cr) => cr.id == cancellation);
        changeMixin = {
          title: cr.description[this.locale],
          confirmLabel: this.$t('confirm'),
          showCancel: true,
          cancellation: cr,
          signViewExclusive: cr.sign_off,
          uploadExclusive: !cr.sign_off,
        };
        this.requestMixin = {
          cancellation_reason_id: cancellation,
          cancellation_reason_category: cr.reason_category,
        };
      } else if (status) {
        let confirmLabel = this.actionsLabels[status];
        changeMixin = {
          title: this.actionsLabels[status],
          status: status,
          confirmLabel,
          showCancel: this.statusActions.length > 1,
          description:
            status === JobStatusEnum.APPROVAL_PENDING
              ? this.$t(
                  'order-details.action-labels.approval-pending-description',
                )
              : undefined,
        };
        this.requestMixin = {
          new_status: status,
        };
      }

      this.changeResult = null;
      this.change = { loading: { form: true } };

      axios
        .post(`/api/partner/orders/set-status`, {
          preview: true,
          job_id: this.job_id,
          ...this.requestMixin,
        })
        .then(async (response) => {
          changeMixin.useSignView = this.checkIfSignView(response.data.inputs);
          this.change = {
            inputs: await this.initInputs(response.data.inputs),
            ...changeMixin,
          };
          this.crCollapse = false;
        });
    },
    prepareEventChange(event) {
      this.changeResult = null;
      this.change = {
        loading: { button: true },
        title: this.$t(`state-events.${event}`),
        confirmLabel: this.$t(`state-events.${event}`),
        event,
      };
      this.sendJobStateEvent();
    },
    prepareProductDelivery(productOrder) {
      this.changeResult = null;
      this.change = {
        loading: { button: true },
        title: this.$t('order-details.confirm-labels.received-product'),
        confirmLabel: this.$t('order-details.confirm-labels.received-product'),
        productOrder,
      };
      this.sendProductDeliveryEvent();
    },
    checkIfSignView(inputs) {
      return (
        (inputs[DOCUMENTATION] &&
          inputs[DOCUMENTATION].state == 'required' &&
          inputs[DOCUMENTATION].variants.includes('protocol_signature')) ||
        (inputs[CANCELLATION_DOCUMENTATION] &&
          inputs[CANCELLATION_DOCUMENTATION].variants.includes(
            'protocol_signature',
          ))
      );
    },
    orderIntakeFormsScrollIntoView(data) {
      const element = this.$refs['order-actions'];
      if (element) {
        element.scrollIntoView({
          behavior: data.behavior,
          block: data.block,
        });
      }
    },
    orderIntakeFormsSummary(data) {
      this.summaryShown = data;
    },
    orderIntakeFormsRedirect(data) {
      if (
        data.job.status === JobStatusEnum.APPROVAL_PENDING &&
        this.order.job.customer_signature_reason !==
          CustomerSignatureReasonEnum.MISSING_CUSTOMER
      ) {
        if (data.job.sfid) {
          this.afterChange(data.job.status);
        }
      } else {
        this.loadData();
        this.change = null;
      }
    },
    orderIntakeFormsFetched(data) {
      const inputIndex = this.change.inputs.findIndex(
        (x) => x.name === 'order-intake-protocol',
      );
      if (inputIndex !== -1) {
        this.change.inputs[inputIndex].extra.fetched = data;
        this.$store.dispatch(JOB_UPDATE_EXTRA_INPUT, {
          jobId: this.job_id,
          inputName: this.change.inputs[inputIndex].name,
          extra: this.change.inputs[inputIndex].extra,
        });
      }
    },
    buildProtocolIntakeComponentOutput() {
      let extra = {
        elementId: this.job_id,
        jobId: this.job_id,
        validationContext: this.validationContext,
        validationPath: 'order-intake-forms',
        opportunitySfid: this.order.opportunity.sfid,
        orderApiId: this.order.api_id,
        accessToken: this.access_token,
      };

      const componentOutput = {
        name: 'order-intake-protocol',
        type: 'required',
        fetchedChunks: [false],
        async: true,
        component: 'order-intake-forms',
        data: {},
        extra,
      };

      return componentOutput;
    },
    async assignComponentOutputDataFromProtocolIntakeForm(
      componentOutput,
      formType,
      protocolType,
    ) {
      const intermediaryFormUrl = `/order-intake-forms-api/form/${this.order.opportunity.sfid}/form-type/${formType}/protocol-type/${protocolType}/category/${FormOrderModificationCategoryEnum.DOCUMENTATION}`;
      let intermediaryForm = null;
      try {
        const intermediaryFormResponse = await axios.get(intermediaryFormUrl);
        intermediaryForm = intermediaryFormResponse.data;
        if (intermediaryForm) {
          componentOutput.extra.locale = intermediaryForm.locale;
          componentOutput.extra.form = intermediaryForm.id;
          componentOutput.extra.theme = intermediaryForm.theme;
          componentOutput.enabled = true;
        }
      } catch (err) {
        console.error(err);
        componentOutput.enabled = false;
      } finally {
        componentOutput.fetchedChunks[0] = true;
      }
      return componentOutput;
    },
    async invokeProtocolIntake(_formType, inputsArray) {
      if (
        [JobStatusEnum.STARTED, JobStatusEnum.FINISHED].includes(
          this.order.job.status,
        )
      ) {
        const formType = _formType;
        let protocolType = 'default';

        if (formType === 'installation') {
          if (
            this.order.opportunity.sfid ===
            VODAFONE_OPPORTUNITY_SENDERNEUSORTIERUNG
          ) {
            protocolType = 'incident';
          }
        }

        let componentOutputDefinition =
          this.buildProtocolIntakeComponentOutput();
        let componentOutput =
          await this.assignComponentOutputDataFromProtocolIntakeForm(
            componentOutputDefinition,
            formType,
            protocolType,
          );
        if (componentOutput.extra.form) {
          inputsArray.push(componentOutput);
        }
      }
    },
    async initInputs(inputs) {
      let inputsArray = [];
      const allowOptionalInputs = [];
      if (this.requestMixin.new_status == 'finished') {
        allowOptionalInputs.push(PLANNING_PARTNER);
      }
      let minDate = this.order.job.readiness_date
        ? moment(this.order.job.readiness_date)
        : this.order.job.date_expected
        ? moment(this.order.job.date_expected)
        : moment(this.order.job.created_date)
            .startOf('day')
            .add(this.order.opportunity.assembly_time_days || 0, 'days');
      if (minDate.isBefore(moment().startOf('day'))) {
        minDate = moment().startOf('day');
      }

      if (this.order.is_precheck) {
        await this.invokeProtocolIntake('precheck', inputsArray);
      } else if (this.order.is_installation) {
        await this.invokeProtocolIntake('installation', inputsArray);
      } else if (this.order.is_meter_application) {
        await this.invokeProtocolIntake('meter_application', inputsArray);
      } else if (this.order.is_installation_preparation) {
        await this.invokeProtocolIntake(
          'installation_preparation',
          inputsArray,
        );
      } else if (this.order.is_maintenance) {
        await this.invokeProtocolIntake('maintenance', inputsArray);
      } else if (this.order.is_defect_elimination) {
        await this.invokeProtocolIntake('defect_elimination', inputsArray);
      }

      for (const inputName in inputs) {
        const inputType = inputs[inputName].state;
        let component = `input-${inputName}`;
        let initData = null;
        let extra = {
          jobId: this.job_id,
          validationContext: this.validationContext,
          validationPath: inputName,
        };
        const componentFetchedChunks = [];
        const componentAsync = false;
        const componentEnabled = true;
        if (
          inputType != 'required' &&
          !allowOptionalInputs.includes(inputName)
        ) {
          continue;
        }

        if (inputName == DELIVERY_DATE) {
          initData = this.order.job.date_expected;
        } else if (inputName == READINESS_DATE) {
          initData = this.order.job.readiness_date;
          extra.onlyAfterDate = moment().startOf('day');
        } else if (inputName == APPOINTMENT_DATE) {
          initData = {
            start: this.order.job.appointment_start_timestamp,
            end: this.order.job.appointment_end_timestamp,
          };
          extra.multiday = this.order.opportunity.multiday;
          extra.title = this.order.job.is_reclamation
            ? this.$t('reclamation-appointment_date')
            : this.$t('appointment_date');
          if (this.order.is_precheck) {
            extra.title = this.$t('precheck-appointment_date');
          }
          extra.disclaimer = this.$t('disclaimer-appointment_date');

          let readiness_date;
          if (this.order.job.readiness_date || this.order.job.date_expected) {
            readiness_date =
              new Date(this.order.job.readiness_date) >
              new Date(this.order.job.date_expected)
                ? new Date(this.order.job.readiness_date)
                : new Date(this.order.job.date_expected);
          }
          extra.readiness_date = readiness_date;
        } else if (
          [DOCUMENTATION, CANCELLATION_DOCUMENTATION].includes(inputName)
        ) {
          component = `input-documentation`;
          extra = {
            ...extra,
            variants: inputs[inputName].variants,
            placeOfSignature: this.order.customer.shipping_address.city,
            ga: {
              opportunityName: this.order.opportunity.name,
            },
            documentationType: this.order.opportunity.documentation_type,
            inputName: inputName,
            customerName: this.order.customer.name,
            context: 'partner',
          };
        } else if (inputName === DOCUMENTATION_PICTURE) {
          component = 'input-files_selector';
          extra = {
            ...extra,
            context: 'partner',
          };
        } else if ([PLANNING_CUSTOMER, PLANNING_PARTNER].includes(inputName)) {
          component = 'input-files_selector';
          extra = {
            ...extra,
            ga: {
              opportunityName: this.order.opportunity.name,
              imagesType: inputName,
            },
            context: 'partner',
          };
        } else if (inputName == PRODUCTS) {
          initData = this.order.products;
        } else if (inputName == SERVICES) {
          initData = [
            ...this.order.services,
            ...this.order.additional_items,
          ].map((service) => ({
            service: service.sfid || service.link_id,
            quantity: service.quantity,
            price_purchasing: service.price_purchasing,
            price_selling: service.price_selling,
            price_lists: service.price_lists,
            value_entry: service.value_entry || 1,
            api_id: service.api_id,
            product_id: service.product_id,
            brand_project_id: service.brand_project_id,
          }));

          extra = {
            ...extra,
            services_endpoint: this.servicesEndpoint,
            currentPricePurchasing: this.order.job.price_purchasing,
            currentPriceSelling: this.order.job.price_selling,
            hidePricePurchasing: this.hidePricePurchasing,
            hidePriceSelling: true,
            showLineTotals: true,
            allowDuplicateServices: this.allowDuplicateServices,
            productId: null,
            existent_custom_services: this.order.custom_services,
            custom_services: this.new_custom_services,
            products: this.order.products,
          };
        } else {
          continue;
        }

        inputsArray.push({
          enabled: componentEnabled,
          name: inputName,
          type: inputType,
          fetchedChunks: componentFetchedChunks,
          async: componentAsync,
          data: initData,
          extra,
          component,
        });
      }

      if (
        this.order.is_precheck ||
        this.order.is_installation ||
        this.order.is_meter_application ||
        this.order.is_installation_preparation ||
        this.order.is_maintenance ||
        this.order.is_defect_elimination
      ) {
        const orderIntakeProtocol = inputsArray.find(
          (x) => x.name === 'order-intake-protocol',
        );
        if (orderIntakeProtocol) {
          if (
            [JobStatusEnum.STARTED, JobStatusEnum.FINISHED].includes(
              this.order.job.status,
            )
          ) {
            const disableInputs = [
              'planning_partner',
              'documentation',
              'documentation_picture',
              'protocol_signature',
            ];
            disableInputs.forEach((inputName) => {
              const index = inputsArray.findIndex((i) => i.name === inputName);
              if (index !== -1) {
                inputsArray[index].enabled = false;
              }
            });
          }
        }
      }

      inputsArray.sort(
        (a, b) => inputsOrder.indexOf(a.name) - inputsOrder.indexOf(b.name),
      );
      this.$store.dispatch(JOB_SET_EDIT, {
        init: true,
        jobId: this.job_id,
        opportunity: this.order.opportunity,
        brandId: this.order.brand.id,
        context: 'partner',
      });
      this.$store.dispatch(JOB_INIT_INPUTS, {
        jobId: this.job_id,
        inputs: inputsArray,
        constrains: {
          minDate,
        },
      });

      return inputsArray;
    },
    confirmChange() {
      const hasCustomServiceFromError = this.customServiceHasErrors();
      if (!hasCustomServiceFromError) {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.change = {
              ...this.change,
              loading: { button: true },
            };
            if (this.change.event) {
              this.sendJobStateEvent();
            } else {
              this.sendChangeRequest();
            }
          }
        });
      }
    },
    sendJobStateEvent() {
      const stateEvent = this.change.event;
      axios
        .post(
          `/jobs-api/status-rules/job/${this.order.job.api_id}/apply-event`,
          {
            type: stateEvent,
          },
        )
        .then(({ data }) => {
          this.changeResult = {
            type: 'success',
            title: this.$t('successful'),
          };
          this.change = null;
          this.loadData(true);
          this.scrollActionsIntoView = true;
          this.$store.dispatch(COUNTS_REFRESH);
          if (stateEvent == 'CUSTOMER_REACHED') {
            this.addCustomerReachedComment();
          }
        });
    },
    sendProductDeliveryEvent() {
      const productOrder = this.change.productOrder;
      axios
        .patch(`/documents-api/product-orders/${productOrder.id}`, {
          trackingID: PARTNER_TRACKING_ID,
          carrier: { id: CARRIER_UNKNOWN_ID },
          comment: {
            message: `
Der Service Partner hat den Empfang der Produkte<br/>
${productOrder.items
  .map((product) => `<strong>${product.name}</strong><br/>`)
  .join('')}
bestätigt.`,
            visibleForRoles: [
              UserRoleEnum.ADMIN,
              UserRoleEnum.BRAND,
              UserRoleEnum.PARTNER,
              UserRoleEnum.CUSTOMER,
            ],
            actionType: CommentActionTypeEnum.GENERIC,
          },
        })
        .then(({ data }) => {
          this.changeResult = {
            type: 'success',
            title: this.$t('successful'),
          };
          this.change = null;
          this.loadData();
          this.scrollActionsIntoView = true;
          this.$store.dispatch(COUNTS_REFRESH);
        });
    },
    sendChangeRequest() {
      axios
        .post(`/api/partner/orders/set-status`, {
          preview: false,
          job_id: this.job_id,
          ...this.requestMixin,
          inputs: this.sendInputs,
        })
        .then((response) => {
          this.order = response.data;
          this.changeResult = {
            type: 'success',
            title: this.$t('successful'),
          };
          this.gaTrack();
          this.afterChange(this.order.job.status);
          this.new_custom_services = [];
          this.signView = false;
          this.change = null;
          this.$store.dispatch(COUNTS_REFRESH);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              const nonInputErrors = filterNonInputErrors(
                error.response.data.errors,
                Object.keys(this.sendInputs),
              );
              if (Object.keys(nonInputErrors).length > 0) {
                this.changeResult = {
                  type: 'danger',
                  title:
                    this.$t('_errors.server.title') +
                    ` - ${error.response.status}`,
                  description: this.$t('_errors.server.details'),
                  validationErrors: nonInputErrors,
                };
              }
              this.$store.dispatch(`validation/${STORE_API_ERRORS}`, {
                context: this.validationContext,
                errors: error.response.data.errors,
              });
            } else {
              const { details } = this.stipFilesFromInput(error.response);
              this.changeResult = {
                type: 'danger',
                title:
                  this.$t('_errors.server.title') +
                  ` - ${error.response.status}`,
                description: this.$t('_errors.server.details'),
                details,
              };
            }
          } else {
            this.changeResult = {
              type: 'danger',
              title: this.$t('_errors.network.title'),
              description: this.$t('_errors.network.details'),
            };
          }
          this.signView = false;
          this.change.loading = false;
        });
    },
    afterChange(status) {
      switch (status) {
        case JobStatusEnum.OPEN:
          this.$router.push({ name: 'offer', params: { job_id: this.job_id } });
          break;
        case JobStatusEnum.FINISHED:
        case JobStatusEnum.APPROVAL_PENDING:
        case JobStatusEnum.COMPLETED:
          if (
            this.order.opportunity.can_create_proposal?.includes(
              UserRoleEnum.PARTNER,
            ) &&
            this.order.is_precheck &&
            this.order.is_remote
          ) {
            this.$router.push({
              name: 'create',
              params: { job_id: this.job_id },
            });
            break;
          }
        // eslint-disable-next-line no-fallthrough
        case JobStatusEnum.CLOSED:
        case JobStatusEnum.CANCELLED:
          this.$router.push({
            name: 'order-complete',
            params: { job_id: this.job_id },
          });
          break;
        default:
          this.fetchAvailableActions();
      }
    },
    cancelChange() {
      if (this.signView) {
        this.signView = false;
      } else {
        this.change = null;
        this.changeResult = null;
        this.tryAutoStatusAction();
      }
    },

    showSignView() {
      const hasCustomServiceErrors = this.customServiceHasErrors();
      if (!hasCustomServiceErrors) {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.signView = true;
          }
        });
      }
    },
    /** check if custom service has error, type not assigned to custom service **/
    customServiceHasErrors() {
      if (this.newCustomServicesHaveErrors) {
        this.new_custom_services.forEach((srvc, index) => {
          if (!srvc.service_type) {
            this.$store.dispatch('CUSTOM_SERVICE_PUSH_ERROR', {
              index,
              errorString: 'order-details.custom-service-error.service-type',
            }); // show form error
          } else {
            this.$store.dispatch('CUSTOM_SERVICE_PUSH_ERROR', { index });
          }
        });
        return true;
      } else {
        this.$store.dispatch('CUSTOM_SERVICES_RESET_ERRORS'); // clean form error
        return false;
      }
    },
    createValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_CREATE}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
    destroyValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_DESTROY}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
    gaTrack() {
      if (this.requestMixin.new_status) {
        switch (this.order.job.status) {
          case 'started':
            this.gaTrackStart();
            break;
          case 'appointment confirmed':
            this.gaTrackAppointmentSet();
            break;
          case 'finished':
            this.gaTrackFinish();
            break;
        }
      } else if (this.change.cancellation) {
        this.gaTrackCancellation(this.change.cancellation.reason_category);
      }
    },
    gaTrackStart() {
      const now = this.$moment();
      const nowHour = now.hour();
      const dayOfWeek = now.format('dddd');
      let timeOfDay;

      if (nowHour < 11) {
        timeOfDay = 'Morgen';
      } else if (nowHour >= 11 && nowHour < 13) {
        timeOfDay = 'Mittag';
      } else if (nowHour >= 13 && nowHour < 17) {
        timeOfDay = 'Nachmittag';
      } else {
        timeOfDay = 'Abend';
      }

      this.$gtag.event('Start', {
        event_category: 'Time tracking',
        event_label: `${dayOfWeek} ${timeOfDay}`,
      });
    },
    gaTrackFinish() {
      const dateStart = this.$moment(this.order.date_start);
      const dateCompare = this.$moment();
      const dateDiff = dateCompare.diff(dateStart, 'seconds');

      this.$gtag.event('Stop', {
        event_category: 'Time tracking',
        event_label: 'Seconds',
        event_value: dateDiff,
      });
    },
    gaTrackAppointmentSet() {
      const dateCreated = this.$moment(this.order.job.created_date);
      const dateCompare = this.$moment(
        this.order.job.appointment_start_timestamp,
      );
      const dateDiff = dateCompare.diff(dateCreated, 'days');

      this.$gtag.event('Enter initial date', {
        event_category: 'Appointment date',
        event_label: dateDiff,
      });
    },
    gaTrackCancellation(category) {
      this.$gtag.event(category, {
        event_category: 'Cancellations',
        event_label: this.order.opportunity.name,
      });
    },
    stipFilesFromInput(response) {
      const details = response;
      let usesSignature = false;
      details.config.data = JSON.parse(details.config.data);
      Object.keys(details.config.data.inputs).forEach((key) => {
        const input = details.config.data.inputs[key];
        if (input && input.files instanceof Array) {
          input.files = input.files.map(({ mimetype }) => {
            if (mimetype == 'image/svg+xml') {
              usesSignature = true;
            }
            return {
              data: '...',
              mimetype,
            };
          });
        }
      });
      return { details, usesSignature };
    },
    isStatusAllowed(toStatus) {
      return !(
        this.order.job.status in this.disallowedActions &&
        toStatus.name in this.disallowedActions[this.order.job.status] &&
        this.disallowedActions[this.order.job.status][
          toStatus.name
        ].conditions.some((x) => x === true)
      );
    },
    actionButtonVariantStyle(action) {
      if (
        action.status === 'appointment confirmed' &&
        Object.values(this.statusActions)
          .map((x) => x.status)
          .includes('started')
      ) {
        return 'outline-primary';
      }
      return 'primary';
    },
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-cr-select {
  .dropdown-menu {
    max-width: calc(100vw - #{$grid-gutter-width});
    max-height: calc(100vh - #{$grid-gutter-width});
    overflow-y: auto;
    z-index: $zindex-tooltip;
  }
  .dropdown-item {
    white-space: normal;
  }
}

.gid-sign-view {
  position: fixed;
  z-index: $zindex-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 $grid-gutter-width * 0.5;
  background: $light;
  overflow-x: hidden;
  overflow-y: auto;
}

body.gid-sign-view--open {
  overflow: hidden;
}

.btn.btn-confirm-action-warning {
  background-color: #f98747;
  border-color: #f98747;

  &:hover {
    background-color: darken(#f98747, 10%);
    border-color: darken(#f98747, 10%);
  }
}

.disabled-prompt {
  z-index: 1;
  top: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: #00000033;

  .box {
    background-color: white;
    padding: 2.8rem;
    font-weight: bold;
    font-size: 1.6rem;
  }
}

.oi-disabled {
  opacity: 0.7;
  pointer-events: none;
}
</style>
